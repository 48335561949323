<template>
    <div class="content_body" v-loading="loading">
        <div class="nav_header">
            <el-row>
                <el-col :span="20">
                    <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
                        <el-form-item label="职务名称">
                            <el-input v-model="searchKey" placeholder="输入职务名称" clearable @clear="handleSearch"></el-input>
                        </el-form-item>
                        <el-form-item label="有效性">
                            <el-select v-model="active" placeholder="请选择有效性" clearable @change="handleSearch">
                                <el-option label="有效" :value="true"></el-option>
                                <el-option label="无效" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" @click="handleSearch"  v-prevent-click>搜索</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="4" class="text_right">
                    <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
                </el-col>
            </el-row>
        </div>

        <div class="martp_10">
            <el-table size="small" :data="tableData">
                <el-table-column prop="JobName" label="职务名称"></el-table-column>
                <el-table-column prop="JobDescription" label="职责说明"></el-table-column>
                <el-table-column prop="Active" label="有效性" :formatter="formatStatus"></el-table-column>
                <!--<el-table-column prop="Total" label="员工数量"></el-table-column>-->
                <el-table-column label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="showEditDialog(scope.row)" v-prevent-click>编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pad_15 text_right">
                <el-pagination
                        background
                        v-if="paginations.total > 0"
                        @current-change="handleCurrentChange"
                        :current-page.sync="paginations.page"
                        :page-size="paginations.page_size"
                        :layout="paginations.layout"
                        :total="paginations.total"
                ></el-pagination>
            </div>
        </div>

        <!--职务弹窗-->
        <el-dialog :title="isAdd?'新增职务':'编辑职务'" :visible.sync="dialogVisible" width="40%">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" @submit.native.prevent>
                <el-form-item label="职务名称" prop="JobName">
                    <el-input size="small" v-model="ruleForm.JobName"></el-input>
                </el-form-item>
                <el-form-item label="职责说明">
                    <el-input
                            type="textarea"
                            :autosize="{ minRows: 4, maxRows: 4}"
                            v-model="ruleForm.JobDescription"
                    ></el-input>
                </el-form-item>
                <el-form-item label="在职状态" v-if="!isAdd">
                    <el-radio-group v-model="ruleForm.Active">
                        <el-radio :label="true">有效</el-radio>
                        <el-radio :label="false">无效</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false" v-prevent-click>取 消</el-button>
                <el-button size="small" type="primary" @click="addSubmit" :loading="modalLoading" v-prevent-click>保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import API from "@/api/KHS/Entity/jobtype";

    export default {
      name: "Job",
        data() {
            return {
                loading: false,
                modalLoading: false,
                searchKey: "",
                active: true,
                tableData: [],
                dialogVisible: false, //新增弹窗
                isAdd: true,
                ruleForm: {
                    JobName: "",
                    JobDescription: "",
                    Active: ""
                },
                //新增
                rules: {
                    JobName: [
                        {required: true, message: "请输入职务名称", trigger: "blur"}
                    ],
                    JobDescription: [
                        {required: true, message: "请输入职责说明", trigger: "blur"}
                    ],
                    Active: [
                        {required: true, message: "请选择在职状态", trigger: "change"}
                    ]
                },
                //需要给分页组件传的信息
                paginations: {
                    page: 1, // 当前位于哪页
                    total: 0, // 总数
                    page_size: 10, // 1页显示多少条
                    layout: "total, prev, pager, next,jumper" // 翻页属性
                }
            };
        },

        methods: {
            // //状态显示转换
            formatStatus: function (row) {
                return row.Active ? "有效" : "无效";
            },
            // 数据显示
            handleSearch: function () {
                let that = this;
                that.paginations.page = 1;
                that.search();
            },
            // 数据显示
            search: function () {
                let that = this;
                that.loading = true;
                var params = {
                    JobTypeName: that.searchKey,
                    Active: that.active,
                    PageNum: that.paginations.page
                };
                API.getJobtype(params)
                    .then(res => {
                        if (res.StateCode == 200) {
                            that.tableData = res.List;
                            that.paginations.total = res.Total;
                            that.paginations.page_size = res.PageSize;
                        } else {
                            that.$message.error({
                                message: res.Message,
                                duration: 2000
                            });
                        }
                    })
                    .finally(function () {
                        that.loading = false;
                    });
            },
            // 上下分页
            handleCurrentChange(page) {
                var that = this;
                that.paginations.page = page;
                that.search();
            },

            // 新增
            showAddDialog: function () {
                var that = this;
                that.dialogVisible = true;
                that.isAdd = true;
                that.ruleForm = {
                    JobName: "",
                    JobDescription: ""
                };
            },
            //编辑
            showEditDialog: function (row) {
                var that = this;
                that.dialogVisible = true;
                that.isAdd = false;
                that.ruleForm = Object.assign({}, row);
            },

            //模态窗数据
            addSubmit: function () {
                let that = this;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        that.modalLoading = true;
                        let para = Object.assign({}, that.ruleForm);
                        if (that.isAdd) {
                            API.createJobtype(para)
                                .then(function (res) {
                                    if (res.StateCode === 200) {
                                        that.$message.success({
                                            showClose: true,
                                            message: "新增成功",
                                            duration: 2000
                                        });
                                        that.search();
                                        that.$refs["ruleForm"].resetFields();
                                        that.dialogVisible = false;
                                    } else {
                                        that.$message.error({
                                            showClose: true,
                                            message: res.Message,
                                            duration: 2000
                                        });
                                    }
                                })
                                .finally(function () {
                                    that.modalLoading = false;
                                });
                        } else {
                            var params = {
                                ID: para.ID,
                                JobName: para.JobName,
                                JobDescription: para.JobDescription,
                                Active: para.Active
                            };
                            API.updateJobtype(params)
                                .then(function (res) {
                                    if (res.StateCode === 200) {
                                        that.$message.success({
                                            showClose: true,
                                            message: "编辑成功",
                                            duration: 2000
                                        });
                                        that.$refs["ruleForm"].resetFields();
                                        that.search();
                                        that.dialogVisible = false;
                                    } else {
                                        that.$message.error({
                                            showClose: true,
                                            message: res.Message,
                                            duration: 2000
                                        });
                                    }
                                })
                                .finally(function () {
                                    that.modalLoading = false;
                                });
                        }
                    }
                });
            }
        },
        mounted() {
            var that = this;
            that.handleSearch();
        }
    };
</script>

<style scoped lang="scss">
</style>